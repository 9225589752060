.contentblock {
  color: white;
  box-shadow: 0 0 5px 0 rgba(87, 122, 168, 0.87);
  margin-bottom: 3em;
  border-radius: 0em 0em 1em 1em;
}

.contentblockheader {
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  gap: 0.5em;
}

.contentblockheader-large {
  font-family: "Jenriv Titling";
  font-weight: bold;
  font-size: x-large;
}

.contentblockheader-small {
  font-family: "Jenriv Titling";
  font-weight: bold;
  font-size: large;
}

.contentblock-body {
  background-color: rgba(87, 122, 168, 0.87);
  padding: 1em;
  border-radius: 0em 0em 1em 1em;
}
