@font-face {
    font-family: 'UnZialish';
    src: url('UnZialish.woff2') format('woff2'),
        url('UnZialish.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'We Are Survivors';
    src: url('WeAreSurvivors.woff2') format('woff2'),
        url('WeAreSurvivors.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Jenriv Titling';
    src: url('JenrivTitling-Regular.woff2') format('woff2'),
        url('JenrivTitling-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Calculatrix 7';
    src: url('Calculatrix-7.woff2') format('woff2'),
        url('Calculatrix-7.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
