.paragon-toast {
    padding: 0.25em;
    margin: 0.25em;
    background-color: rgba(0,0,0,0.3);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    color: white;
}

.paragon-toast .toast-header {
    background-color: initial !important;
    color: white;
}

.paragon-toast.success {
    background-color: rgba(0,255,0,0.8);
    color: black;
}

.paragon-toast.success .toast-header {
    color: black;
}

.paragon-toast.warn {
    background-color: rgba(255,255,0,0.8);
}

.paragon-toast.error {
    background-color: rgba(255,0,0,0.8);
}