.row {
  --bs-gutter-x: 0 !important;
}

html {
}

body {
  background: url('./images/webp/background.webp');
  background-color: #233657 !important;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 100%;
  height: auto;
  width: auto;
}

nav {
  height: 180px;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
  font-family: 'Cinzel', serif;
  font-size: 17pt;
}
.navbar .container .navbar-collapse {
  border-bottom: 1px solid lightgray;
}

.custom-modal .modal-content {
  background-color: #2b3c4f;
  border-radius: 10px;
  text-align: center;
}

.custom-modal .modal-header {
  color: #fff;
  background-color: #16202b;
  border: none !important;
  text-align: center;
  padding-bottom: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-family: 'Cinzel', serif;
  justify-content: center;
}

.custom-modal .modal-header p {
  color: #fff;
  font-size: medium;
  border: none !important;
}

.modal-login-form, .modal-forgot-password-form {
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.modal-login-form input, .modal-forgot-password-form input {
  text-align: center !important;
}

.modal-login-form label, .modal-forgot-password-form label {
  width: 100%;
}

.custom-modal .form-floating>.form-control:focus~label, .custom-modal .form-floating>.form-control:not(:placeholder-shown)~label, .custom-modal .form-floating>.form-select~label {
  transform: translateY(-.9rem);
}

.facebook {
  color: #0d8bf0;
  transition: color 0.15s;
}
.discord {
  color: #5662f6;
  transition: color 0.15s;
}
.facebook:hover {
  color: #47a5ff;
}
.discord:hover {
  color: #767bff;
}

.xtreme-img {
  opacity: 0.7;
  transition: opacity 0.15s;
}

.xtreme-img:hover {
  opacity: 1;
}

.nav-bar-dropdown .dropdown-menu {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;
  background: radial-gradient(rgb(109, 158, 214), rgba(53, 85, 122, 1.0));
}

.nav-bar-dropdown .dropdown-menu .dropdown-item {
  color: white;
  font-weight: 500;
}
.nav-bar-dropdown .dropdown-menu .dropdown-item:hover, .nav-bar-dropdown .dropdown-menu .dropdown-item.active {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.nav-dropdown-heading {
  margin-left: 0.5em;
  color: white;
  font-weight: 500;
  font-size: large;
  user-select: none;
}

.account-option {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.8em;
  background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
  padding: 1.3em;
  border: 2px solid rgba(90, 90, 90, 0.5);
  border-radius: 0.3em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  width: 97%;
  height: 94%;
  user-select: none;
  cursor: pointer;
}

.account-option.disabled {
  opacity: 0.6;
  cursor: unset;
  user-select: none;
}


.account-option span {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.account-option p {
  font-size: 14px;
  margin: 0;
}

.header {
  height: 20em;
  margin: 3em 0 3em 0;
}

.header-left {
  font-family: 'UnZialish'; 
  font-weight: 500; 
  font-style: normal;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 3px #22aeff, 0 0 5px #3146ff;
  text-align: center;
  height: 20em;
  display:flex;
  align-items: center;
  justify-content: center;
  background-image: url("./images/logo-ice2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.header-right {
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
}

.header-box {
  width: 15em;
  display: inline-block;
  color: #fff;
  background: radial-gradient(rgb(109, 158, 214), rgba(53, 85, 122, 1.0));
  border: 1px solid white;
  padding: 1em;
}

.header-box span {
  font-weight: bold;
}

.header-carousel {
  margin-bottom: 3em;
}
.carousel-inner img {
  height: 20em;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
}

.carousel-caption {
  margin-bottom: 7em;
}

.main-content {
  position: relative;
  background: radial-gradient(rgba(139, 176, 217, 1.0), rgba(53, 85, 122, 1.0));
  background-position:center;
  background-repeat: no-repeat;
  background-size:cover;
  padding: 0 !important;
  padding-top: 3em !important;
  min-height: 75em;
  border-radius: 30px;
  border-right: 1px white solid;
  border-left: 1px white solid;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.main-right {
  text-align: center;
}

.main-right .main-container {
  text-align: left;
  display: inline-block;
  width: 80%;
}

.white {
  color: white !important;
}

.green-reg-message:not(:empty) {
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(25,135,84,.9);
  border-radius: .25rem;
}

.red-reg-message:not(:empty) {
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(220,53,69,.9);
  border-radius: .25rem;
}

.center {
  text-align: center;
}

.registration {
  padding: 2em;
}

.register-captcha {
  display: inline-block;
}

.registration .regform {
  width: 50%;
  margin-top: 3em;
  margin-left: auto;
  margin-right: auto;
}

.registration .regform .mb-3 {
  display: inline-block;
  width: 304px;
}

#registration-output-text {
  width: 304px;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1.25em;
}

.is-valid~.valid-feedback, .is-valid~.valid-tooltip, .was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip,
.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
    display: flex !important;
}

.invalid-tooltip, .valid-tooltip {
  right: 105%;
  top: -2px !important;
  height: 99%;
  width: 15em;
  align-items: center;
  justify-content: center;
}

.event-table td {
  border-bottom-width: 0px !important;
}

.event-table {
  max-width: 100% !important;
  background-color: rgba(0,0,0,0.15);
}

.event-table thead {
  background-color: rgba(0,0,0,0.15);
}

.event-table th {
  padding: 1em 0.3em !important;
  color: rgb(255, 255, 255);
}

.download-notification {
  background-color: rgba(0, 0, 0, 0.15);
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-bottom: 50px;
}

.download-notification h3 {
  font-size: 20px;
  padding: 6px 0;
}

.download-notification .dln-header{
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: rgba(0,0,0,0.3);
}

.download-notification .dln-content {
  text-align: left;
  width: 70%;
  margin: auto;
}

.download-notification p {
  margin: 0;
  color: rgb(245, 245, 245);
}

.download-notification ul {
  margin-top: 10px;
}

.download-notification a {
  color: white !important;
}

.download-problems {
  margin-bottom: 50px;
}

.donation-div {
  background-color: rgba(0,0,0,0.5);
  padding: 1em;
  border-radius: 4px;
}

.dps-notice {
  font-style: italic;
  color: lightgray;
}

.donation-form {
  display: flex;
  justify-content: center;
}

.donation-buttons {
  display: block;
}

.donation-buttons .btn {
  background-color: #0070ba !important;
  margin: 0.5em 0.5em 0.5em 0;
}

.paypal-button-overlay {
  cursor:not-allowed;
}

.sidebar-account {
  background-color:rgba(0, 0, 0, 0.15) !important;
  border-radius: 1rem !important;
  border-style: none !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.sidebar-account .left-col {
  margin-left: 0.5em;
}

.logged-welcome {
  font-weight: bold;
  font-size: large;
  color: white;
}

.logged, .logged:hover {
  color: orange;
  text-decoration: underline;
  cursor:pointer;
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.modal-login-form div.forgot {
  text-align: right;
  margin-bottom: 0.5em;
}

.mb-3.margin-custom, .margin-custom .form-floating {
  margin-bottom: 0 !important;
}

.forgot-password-link, .forgot-password-link:hover {
  display: block;
  color: rgb(255, 194, 81);
  padding: 0.3em;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}

.form-header {
  text-align: center;
}

.form-header span {
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}

.form-header p {
  color: #fff;
  font-size: medium;
  font-weight: 500;
}

.login-form-success:not(:empty) {
  margin-bottom: 1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(53, 220, 75, 0.9);
  border-radius: .25rem;
}

.login-form-error:not(:empty) {
  margin-bottom: 1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(220,53,69,.9);
  border-radius: .25rem;
}

.login-form-loading:not(:empty) {
  margin-bottom: 1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  color: #fff;
  background-color: #0c52bb;
  border-radius: .25rem;
}

.reset-form-page {
  text-align: center;
}

.reset-form {
  width: 50%;
  margin: 3em auto;
}

.votebutton {
  margin-top: 2em;
  text-align: center;
}

.votebutton a {
  background-color: white;
  width: 80%;
  font-size: xx-large;
  border: none;
  animation-name: votebutton;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.download-content {
  display: inline-block;
  width: 100%;
}

.download-button {
  padding: 1em !important;
  font-size: larger !important;
  font-weight: 500 !important;
  margin: 0 1%;
}

.download-accordion .accordion-button {
  background: radial-gradient(rgb(109, 158, 214), rgb(75, 121, 173));
  font-weight: 700;
  font-size: large;
}

.download-accordion .accordion-button:not(.collapsed) {
  color: black;
}

.download-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.download-accordion .accordion-body {
  background: radial-gradient(rgb(109, 158, 214), rgb(134, 182, 238));
  text-align: left;
  color: black;
}

.download-accordion .accordion-item {
  background-color: rgba(0,0,0,0);
}

.rules-head {
  border-bottom: 2px solid white;
}

.rules-nav .btn {
  margin: 0.3em;
}

.rules-body {
  border-top: 2px solid white;
}

.rules-body .rule {
  padding: 1em;
  margin: 1em;
  background-color: rgba(0,0,0,0.3);
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}

.rules-body .rule h5 {
  padding-bottom: 0.5em;
  border-bottom: 1px solid lightgray;
}

.rules-body .rule p, .rules-body .rule li {
  color: #fff;
}

.guide-header {
  background-color: rgba(0,0,0,0.3);
  border-bottom: 1px solid lightgray;
  padding: 1em;
  border-radius: 1em 1em 0em 0em;
}

.guide-page {
  background-color:rgba(0, 0, 0, 0.3) !important;
  border-radius: 0.25rem !important;
  border-style: none !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  min-height: 30em;
}

.guide-nav {
  background-color: rgba(0,0,0,0.2);
  border-bottom: 1px solid lightgray;
}

.guide-nav .nav-item a.nav-link {
  color: #ddd;
}

.guide-nav .nav-item a.nav-link:hover,.guide-nav .nav-item a.nav-link.active {
  color: #fff;
}

.guide-nav a.dropdown-item {
  color: #ddd;
  text-shadow: -1px 1px 0 #000,
                          1px 1px 0 #000,
                         1px -1px 0 #000,
                        -1px -1px 0 #000;
}

.guide-nav a.dropdown-item.active {
  color: #fff;
  background: radial-gradient(rgb(83, 83, 83), rgb(0, 0, 0));
}

.guide-nav a.dropdown-item:hover {
  color: #fff;
  background-color: rgba(255,255,255,0.2);
}

.guide-nav .dropdown-menu {
  background-color: rgba(0,0,0,1);
}

.guide .head {
  font-weight: 500;
  font-size: larger;
}

.guide .instruction {
  font-size: large;
}

.guide .tip {
  font-size: medium;
  font-style: italic;
}

.guide img {
  margin: 1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.guide a {
  color: #fff;
}

.guide a:hover {
  color: #aaa;
}

.guide {
  padding: 2em;
}

.link-object span {
  text-decoration: none;
  color: burlywood !important;
  cursor: pointer;
  font-size: medium;
}

.plogTabHeader {
  text-align: center;
}

.plogTabHeader h3 {
  border-bottom: 3px dashed #3e5670;
  padding-bottom: 8px;
  max-width: 60%;
  margin: 0 auto;
  color: white;
  font-weight: 300;
  font-size: 40px;
}

.plogTabHeader p {
  font-family: Arial;
  font-size: 18px;
  color: #273647;
  font-style: italic;
  margin: 16px auto;
  padding-bottom: 16px;
  max-width: 60%;
  border-bottom: 3px dashed #3e5670;
}

.plogTabHeader .btn {
  margin: 0 10px;
}

.plogTabHeader .btn.btn-secondary {
  background-color: #3e5670;
  border-color: #3e5670 !important;
}

.plogTabHeader .btn.btn-outline-secondary {
  border-color: #3e5670 !important;
  color: #3e5670 !important;
}

.plogTabHeader .btn.btn-outline-secondary:hover {
  background-color: #3e5670 !important;
  color: white !important;
}

.credited {
  font-weight: bold;
  color: lime;
}

.pending {
  font-weight: bold;
  color: yellow;
}

.loading-container {
  color: white;
  text-align: center;
  font-size: 48px;
}

.loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 10px dashed white;
  animation: loadspin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes loadspin {
  0%   {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

.no-history {
  text-align: center;
}

.plog-tooltip {
  color: white;
  font-style: italic;
  text-align: center;
  margin-bottom: 0;
}

.payment-logs {
  margin-top: 30px;
}

.payment-logs > .row {
  padding: 8px 20px 8px 0;
  background-color: #3e5670;
  color: white;
}

.payment-logs > .row:first-child {
  font-size: 18px;
}

.payment-logs .accordion-item {
  border: none;
  background-color: transparent;
}
.payment-logs .accordion-button {
  padding: 4px 0;
  background-color: transparent;
}
.payment-logs .accordion-item:nth-child(2n),
.payment-logs .accordion-button:nth-child(2n) {
  background-color: rgba(0,0,0,0.1);
}

.payment-logs .accordion-button .row {
  width: 100%;
}

.payment-logs .col {
  padding: 0 0 0 4px;
}

.payment-logs p:last-child {
  color: white;
  font-style: italic;
  text-align: center;
}

.prizenpc-table {
  margin-bottom: 0 !important;
}

.prizenpc-table thead,
.prizenpc-table .totals {
  background-color: #3e5670 !important;
  font-size: 18px;
  color: white;
}

.prizenpc-table .totals td {
  padding: 8px 0 !important;
}

.prizenpc-table thead th {
  font-weight: normal;
  border-bottom: none;
}

.prizenpc-table td {
  border-bottom: none;
  padding: 0 0 0 4px !important;
}

.prizenpc-table tbody tr:nth-child(2n) {
  background-color: rgba(0,0,0,0.1);
}

@keyframes votebutton {
  0%   {background-color:rgb(165, 127, 1); color: white; width: 90%;}
  50%  {background-color: rgb(255, 249, 168); color: black; width: 50%;}
  100% {background-color:rgb(165, 127, 1); color: white; width: 90%;}
}

.discord-widget {
  margin-top: 2em;
  text-align: center;
}

.sidebar-object {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 1rem !important;
}

.sidebar-object:first-child {
  margin-top: 0;
}

.sidebar-header {
  background-color:rgba(0,0,0,0.7) !important;
  border-radius: 1rem 1rem 0px 0px !important;
  border-style: none !important;
  text-align: center;
  color: rgb(233, 233, 233);
  padding: .5em;
  -webkit-user-select: none;       
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

.h-large {
  font-family: 'Jenriv Titling'; 
  font-weight: bold;
  font-size: x-large;
}
.h-xlarge {
  font-family: 'Jenriv Titling'; 
  font-weight: bold;
  font-size: xx-large;
}
.h-small {
  font-family: 'Jenriv Titling';
  font-weight: bold;
  font-size: large;
}

.sidebar-body {
  background-color:rgba(0, 0, 0, 0.5) !important;
  text-align: center;
  color: rgb(233, 233, 233);
  padding: 1em;
}

.sidebar-body label {
  color: black !important;
}

.foothead {
  font-size: 16px;
  color: white;
  font-weight: 500;
}

.useful-links {
  padding-left: 1em;
  text-align: left;
}

.update-notes {
  padding-left: 1em;
  text-align: left;  
}

.update-notes .link-object span {
  display: block;
  padding: 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.update-notes-more {
  text-align: right;
}

.nobility-reset {
  display: inline-block;
  background-color: black;
  padding: 0.7em;
  padding-bottom: 0.2em;
  background-color: rgba(37, 57, 122, 0.8);
  font-family: 'Calculatrix 7';
  font-size: x-large;
  border-radius: 0.5em;
  border: 5px solid black;
  color: rgb(0, 217, 255);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.announcement {
  border-bottom: 2px solid lightgray;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.announcement-header {
  border-bottom: 1px solid lightgray;
}

.announcement-header h3 {
  display: inline;
  background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(182, 182, 182));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.announcement-header p {
  margin-left: 1em;
  display: inline;
  color:rgb(255, 255, 255);
}

.announcement-content {
  padding-top: 0.5em;
  color:rgb(0, 0, 0);
}

.game-features, .info-page {
  color: white;
  margin-bottom: 3em;
}

.info-header {
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.5);
}

.info-header {
  text-align: center;
}

.info-text {
  background-color: rgba(87, 122, 168, 0.87);
  padding: 1em;
  border-radius: 0em 0em 1em 1em;
}

.info-text p {
  color: rgb(218, 218, 218);
}


.video-object {
  background-color: black;
  border-radius: 1em;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
}

.video-header {
  text-align: center;
  padding: 1em 1em 0em 1em;
  color: white;
}

.video-body iframe {
  width: 100%;
  height: 425px;
}

.video-body {
  padding-bottom: 1em;
}

.discord-frame {
  width: 100%;
  height: 30em;
}

.upper-container {
  margin-bottom: 170px;
}

.update-notes-view-all {
  text-align: center;
}

.update-notes-view-all a {
  margin-top: 1em;
}

.character-info {
  margin: 2em auto;
  background: radial-gradient(rgb(109, 158, 214), rgb(75, 121, 173));
  padding: 1em;
  border-radius: 1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  width: 70%;
}

.character-info .avatar {
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.character-info .avatar img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

.character-info .tags span {
  display: block;
  text-align:left;
  font-weight: bold;
}

.character-info .info span {
  display: block;
  text-align: left;
}

.ticket {
  border: 1px solid black;
  border-radius: 1em;
  background: radial-gradient(rgba(139, 176, 217, 1.0), rgb(84, 135, 192));
  color: black;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.ticket .meta {
  background: radial-gradient(rgba(139, 176, 217, 1.0), rgba(53, 85, 122, 1.0));
  border-radius: 1em 1em 0em 0em;
  border-bottom: 1px solid black;
  padding: 1em;
  text-align: left;
}

.ticket .body {
  padding: 1em;
}

.ticket .message {
  background: radial-gradient(rgba(139, 176, 217, 1.0), rgb(72, 111, 155));
  border: 1px solid black;
  border-radius: 0.5em;
}

.ticket .info {
  padding: 1em;
}

.ticket .info p {
  margin: 0;
}

.ticket .content {
  border-left: 1px solid black;
  text-align: left;
  padding: 1em;
}

.ticket-msg-content {
  white-space: pre-wrap;
}

.ticket .timestamp {
  border-left: 1px solid black;
  text-align: left;
  padding-left: 1em;
  font-style: italic;
  font-size: small;
  padding-top: 1em;
}

.ticket .reply .timestamp {
  border-right: 1px solid black;
  border-left: none;
  text-align: right;
  padding-left: 0;
  padding-right: 1em;
}

.ticket .reply .content {
  border-left: none;
  border-right: 1px solid black;
}

.ticket textarea {
  min-height: 100px !important;
  border-radius: 0.5em;
  border: 1px solid black;
}

.ticket button {
  margin: 0.2em;
  margin-top: 0.7em;
}

.ticket-list-container {
  background-color: rgba(0,0,0,0.6);
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 1em;
}

.ticket-list-container .item {
  background:  rgb(96, 136, 182);
  margin: 2px 0px 2px 0px;
  padding: 1em;
  font-size: small;
}

.ticket-list-container .item:hover {
  background:  rgb(128, 168, 214);
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: pointer;
}

.ticket-list-toggle {
  font-size: small;
  text-align: right;
}

.ticket-list-toggle input {
  margin-right: 1em;
  margin-left: 0.5em;
}

.ticket-list-header {
  font-size: x-large;
  margin-bottom: 0.5em;
}

.no-tickets {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 1em;
}

.new-ticket-button {
  text-align: right;
  padding-right: 1em;
  padding-top: 1em;
}

.new-ticket-form {
  background-color: rgba(0,0,0,0.6);
  padding: 1em;
  border-radius: 1em;
}

.new-ticket-form h3 {
  border-bottom: 2px solid gray;
  padding-bottom: 0.25em;
  margin-bottom: 0.75em;
}

.new-ticket-form textarea {
  min-height: 10em !important;
}

.reply-area {
  text-align: right;
  padding: 1em;
}

.clickable {
  cursor: pointer;
}

.fake-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.update-note {
  color: white;
}

.update-note .note-header {
  background-color: rgba(0,0,0,0.7);
  border-radius: 1rem 1rem 0rem 0rem;
  font-weight: 300;
  padding: 48px;
  padding-bottom: 1rem;
}

.notes-return-link {
  display: block;
  color: burlywood;
  cursor: pointer;
  margin-bottom: 16px;
}

.update-note .note-header h2 {
  font-weight: 300;
}

.update-note .note-header p {
  margin-bottom: 0;
}

.update-note .note-body {
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(0,0,0,0.5);
  font-weight: 300;
  padding: 1rem 48px;
  font-size: 18px;
  min-height: 300px;
}

.update-note-content {
  white-space: pre-wrap;
  width: 100%;
  padding-bottom: 16px;
}

.line-break {
  width: 100%;
}

.update-note-footer {
  display: flex;
  width: 100%;
  align-self: flex-end;
}

.update-note-footer hr {
  width: 100%;
  color: burlywood;
}

.update-note-footer .notes-return-link {
  width: 100%;
  text-align: center;
}

.update-note-footer .notes-return-link:hover {
  text-decoration: underline;
}

.title {
  font-size: 24px !important;
}

.update-notes-header {
  background-color: rgba(0,0,0,0.7);
  padding: 16px 10px;
  font-size: 22px;
  color: white;
  border-radius: 1rem 1rem 0 0;
}

.updateNoteRow {
  padding: 10px;
  color: #dddddd;
  transition: all 0.3s;
}

.updateNoteRow:hover {
  box-shadow: 0 0 5px 0 white inset;
  color: #fff;
  cursor: pointer;
}

.updateNoteRow > div {
  transition: all 0.3s;
}

.updateNoteRow:hover > div {
  padding-left: 15px;
}

.updateNoteRow:nth-child(2n) {
  background-color: rgba(0,0,0,0.5);
}

.updateNoteRow:nth-child(2n+1) {
  background-color: rgba(0,0,0,0.3);
}

footer {
  border-top: 2px gray double;
  background-color: rgba(81, 88, 104, 0.507);
  text-align: center;
  color: white;
  position:absolute;
  min-width: 100%;
  bottom: 0;
  padding: 1em;
  height: 156px;
}

.footer-vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide {
  display: none;
}

.col-lg-10 .main-content {
  width: 100%;
}

p.tip[href] {
  opacity: 0.8;
  text-decoration: underline;
  transition: opacity 0.15s;
}

p.tip[href]:hover {
  opacity: 1;
  cursor:pointer;
}

.dark-box {
  background-color: rgba(0,0,0,0.5);
  padding: 1rem;
  border-radius: 10px;
}

.input-right-align {
  text-align: right;
  padding-right: 1rem;
}

.input-number-normal::-webkit-outer-spin-button,
.input-number-normal::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-number-normal {
  -moz-appearance: textfield;
}

/* DPS Sale */

.dp-sale-on-page {
  background-image: linear-gradient(0deg,#cf5c5c,#c19b4a,#def2a0,#c6ee4a,#42eca6,#64b3d9,#208ea2,#498ada,#5b73df,#897ed3,#cf5c5c,#c19b4a);
  background-size: 100% 1100%;
  text-align: center;
  padding: 4px 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  animation: dpSaleBG 5s linear infinite;
  margin: 20px 0;
}

.dp-sale-on-page-content {
  display: inline-block;
  width: calc(100% - 8px);
  background-color: rgba(81, 88, 104, 0.907);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 0 10px 0 gray;
  padding: 20px 20px;
}

.dp-sale-on-page-content p {
  text-align: justify;
  text-align-last: center;
  padding: 0 100px;
}

.dp-sale-on-page-content table {
  margin: 0 auto;
}

.dp-sale-on-page-content table th,
.dp-sale-on-page-content table td {
  padding: 0 10px;
}

.dp-sale-on-page-content table td {
  padding-top: 5px;
}


.dp-sale-on-page-content table tbody tr {
  border-bottom: 1px solid white;
}

.extra-dps-display {
  font-style: italic;
  font-size: 15px;
  font-family: Helvetica;
  user-select: none;
}

@keyframes dpSaleBG {
  0% { background-position: 0% 0%; }
  100% { background-position: 0% 100%; }
}

/* Side Notifier */

.side-notifier {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;

  max-width: 450px;
  
  border-left: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  box-shadow: 0 0 5px 0 lightblue;

  padding-right: 50px;

  animation: sNotifInAnimation 1s linear;
  overflow: hidden;
  transition: left 0.3s;
}

.side-notif-header {
  background: radial-gradient(rgba(139, 176, 217, 1.0), rgb(105, 171, 247));
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  color: white;
  padding: 8px 20px;
  border-bottom: 1px solid black
}

.side-notif-body {
  background-color: white;
  padding: 8px 20px;
  text-align: center;

}

.side-notif-body span {
  display: block;
  width: 100%;
  font-size: 18px;
  text-align: justify;
  text-align-last: center;
  padding: 8px 0;
}

.side-notif-body a {
  display: inline-block;
  background-color: rgb(105, 171, 247);
  color: white;
  text-decoration: none;
  text-align: center;

  margin: 0 auto;
  padding: 8px 20px;

  transition: color 0.3s, background-color 0.3s;
}

.side-notif-body a:hover {
  background-color: hsl(212, 90%, 79%);
}

.side-notif-hide {
  position: absolute;
  width: 50px;
  height: 100%;
  right: 0;
  top: 0;
  background: radial-gradient(rgb(128, 126, 33), rgb(94, 65, 13));
  cursor: pointer;
}

.side-notif-hide div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.side-notif-hide div span {
  display: block;
  color: white;
  font-family: Helvetica;
  font-weight: 300;
  transform: rotate(-90deg);
  letter-spacing: 2px;
  font-size: 28px;
  user-select: none;
  pointer-events: none;
}

@keyframes sNotifInAnimation {
  0% { left: -400px; }
  100% { left: 0px; }
}


.store-item {
  width: 80%;
  margin: 0 auto;
  color: white;

  background-color: rgba(0,0,0,0.5);
  padding: 16px;
  border: 1px solid white;

  min-height: calc(100% - 32px);

  margin: 0 auto 32px;
}

.store-item .details-button {
  background-color: rgba(255,255,255,0.2);
  border: none;
  border-radius: 10px;
  padding: 0 10px;
  color: white;
}

.store-item .details-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.store-item .item-name {
  overflow: hidden;
  word-break: break-all;
}

.store-item .item-line2 {
  margin-top: 16px;
  margin-bottom: 8px;
}

.store-item hr {
  margin: 8px 0;
}

.white-hr {
  background-color: white;
  height: 1px;
  opacity: 1;
  margin-top: 0;
}

.store-categories {
  background-color: rgba(0,0,0,0.5);
  border: 1px solid white;
}

.store-categories p {
  border-bottom: 1px solid white;
  padding-bottom: 8px;
  margin-bottom: 0px;
}

.store-categories p,
.store-categories a {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #ffffffaa;
}

.store-categories a:hover {
  color: #ffffffcc;
  background-color: rgba(255, 255, 255, 0.1);
}

.store-categories a.active {
  background-color: rgba(255,255,255,0.6);
  color: #000;
}

.store-categories p {
  color: white;
  text-align: center;
  padding-top: 8px;
}

.item-info-display {
  padding: 0 32px;
}

.return-to-store {
  color: #ffffff;
  float: right;
}

.return-to-store:hover {
  text-decoration: underline;
  cursor: pointer;
}

.header-skewed {
  display: inline-block;
  position: relative;
  background-color: #1b232f;
  border-radius: 10px;
}

.header-skewed::after {
  position: absolute;
  content: '';
  left: 50px;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #1b232f;
  transform: skew(-20deg);
  z-index: 2;
}

.header-skewed::before {
  position: absolute;
  content: '';
  left: 60px;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  transform: skew(-20deg);
  z-index: 1;
}

.header-skewed-text {
  position: relative;
  z-index: 3;
  color: white;
  font-size: 24px;
  font-family: Helvetica;
}

.header-skewed img {
  width: 56px;
  height: 56px;
  background-color: black;
  padding: 10px;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.staff-announcements-table {
  color: white;
  border-radius: 1rem;
  overflow: hidden;
}

.staff-announcements-table .row > div {
  padding: 10px;
}

.staff-announcements-table .sat-header {
  background-color: rgba(0,0,0,0.7);
  font-size: large;
}

.staff-announcements-table .sat-items {
  background-color: rgba(0,0,0,0.5);
}

.staff-announcements-table .sat-items .row:nth-child(2n) {
  background-color: rgba(0,0,0,0.2);
}

.sat-footer {
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  padding: 10px 20px;
}

.table-actions {
  display: flex;
  justify-content: space-evenly;
}

.form-button-right {
  display: flex;
  justify-content: flex-end;
}

.updateNoteEditModal .modal-content {
  background: radial-gradient(rgba(139, 176, 217, 1.0), rgba(53, 85, 122, 1.0));
  color: white;
  border-top: 5px solid #93bbf0;
  border-bottom: 5px solid #93bbf0;
  border-left: 1px solid #93bbf0;
  border-right: 1px solid #93bbf0;
  border-radius: 1rem;
}

.justify-right {
  display: flex;
  justify-content: flex-end;
}

.justify-left {
  display: flex;
  justify-content: flex-start;
}

.btn.paragon-button {
  background-color: #4f3ac4;
  border-color: #4f3ac4;
}

.btn.paragon-button:hover,
.btn.paragon-button:focus,
.btn.paragon-button:active {
  background-color: #9066f2;
  border-color: #9066f2;
}

.btn.paragon-button:focus,
.btn.paragon-button:active {
  box-shadow: 0 0 0 0.25rem #4f3ac477;
}

span.command {
  display: inline-block;
  font-family: 'Courier New', Courier, monospace;
  background-color: rgba(0,0,0,0.6);
  user-select: text;
  padding: 0.3rem;
  padding-left: 0.6rem;
  border-left: 2px solid aqua;
}

.margin-command-list li {
  margin: 1rem 0;
  user-select: none;
}

@media (max-width: 1024px) {

  .dp-sale-on-page-content p {
    padding: 0 10px;
  }

  body {
    background: url('./images/webp/background-tablet.webp');
    background-color: #233657 !important;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .mobile-hidden {
    display: none !important;
  }

  .invalid-tooltip, .valid-tooltip {
    position: relative !important;
    z-index: initial !important;
    right: 0%;
    top: 0.5em !important;
    height: 100%;
    width: 100% !important;
    align-items: center;
    justify-content: center;
  }

  .registration .regform {
    width: 90%;
    margin-top: 3em;
    margin-left: auto;
    margin-right: auto;
  }

  .character-info {
    margin: 2em auto;
    width: 100%;
  }
}

@media (max-width: 600px) {
  body {
    background: url('./images/webp/background-mobile.webp');
    background-color: #233657 !important;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (max-width: 575px) {
  .main-content {
    border: none;
    border-top: 1px solid white;
    border-radius: 0;
  }
}

.hidden {
  display: none !important;
}